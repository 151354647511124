import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import Layout from '../components/Layout'
import { FullScreenOverlay } from 'components'
import { GlobalStyles } from 'styles'
import { theme } from '../styles'

const Container = styled.div`
  background-color: ${theme.colors.secondary};
  width: 100%;
  height: 100%;
  color: ${theme.colors.primary};
  margin: 0;
`

const IndexPage = () => (
  <Layout>
    <GlobalStyles />
    <FullScreenOverlay>
      <h1 style={{ color: `${theme.colors.neutral}` }}>Coming Soon...</h1>
    </FullScreenOverlay>
    <Container>
      <h1>Hi there!</h1>
      <p>
        <strong>Thanks for using awesome-gatsby-starter!</strong> Remember to{' '}
        <a href="https://github.com/RobertoMSousa/gatsby-typescript-storybook-starter">drop a ⭐ on the project</a> if
        you find it useful.
      </p>
      <Link to="/page-2/">Go to page 2</Link>
    </Container>
  </Layout>
)

export default IndexPage
